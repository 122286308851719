.App{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.icon-custom{
    font-size: 1.6rem;
    color: white;
}

.highlight{
    color: #f03c02;
}

.details{
    margin-bottom: 20px !important;
}

.centerd-cta{
    justify-content: center !important;
}

.address-map{
    border: 0; 
    width: 100%; 
    height: 350px;
}

.breadcrumbs{
    clear: both;
}

@media(max-width:768px){

    .mobile-off{
        display: none;
    }

    .contact-info{
        margin: 0 auto !important;
    }

    .header-nav-mobile{
        display: block !important;
        text-align: center;

        h1{
            a{
                color: #f03c02 !important;
                font-size: 22px;
                text-align: center;
            }
        }

        nav{
            margin: 0 auto;
            text-align: center;
            width: 100%;
            
            ul{
                margin: 0 auto;
                
            }
        }
    }
}